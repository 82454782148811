import { css } from '@styled-system/css'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import { responsiveScale } from '../../styles/helpers'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import CardFrame from '../primitives/CardFrame'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'
import { Quote } from './QuoteBlock'

import { BlockPaddingProps } from './types'

type Props = BlockPaddingProps & {
  title: ReactNode
  content: ReactNode
  image: IGatsbyImageData
  imageAlign?: 'left' | 'right'
  imageFrame?: boolean
  quote: Quote
}

const MosaicBlock = ({
  title,
  content,
  image,
  imageAlign = 'left',
  imageFrame = true,
  quote,
  paddingTop = responsiveScale(64),
  paddingBottom = responsiveScale(64),
}: Props) => {
  const gatsbyImage = <GatsbyImage image={image} alt="" />
  return (
    <Block>
      <Row
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        space={{ xs: 0, sm: 32 }}
        width="100%"
        flexDirection={{
          xs: 'column',
          sm: imageAlign === 'right' ? 'row-reverse' : 'row',
        }}
      >
        <Box flex={0} alignContent="center" flexBasis={{ xs: 'none', sm: 652 }}>
          {imageFrame ? (
            <CardFrame width="100%">{gatsbyImage}</CardFrame>
          ) : (
            gatsbyImage
          )}
          <Box
            paddingLeft={32}
            paddingTop={24}
            paddingBottom={{ xs: 0, md: 12 }}
          >
            <SmallQuote quote={quote} />
          </Box>
        </Box>
        <Stack
          space={24}
          paddingTop={{ xs: 32, sm: 48 }}
          paddingX={32}
          flex={1}
          flexBasis={{ xs: 'none', sm: 500 }}
        >
          <Box.h2 font="h2" maxWidth="mosaic">
            {title}
          </Box.h2>
          <Box font="large" maxWidth="mosaicText" className="links">
            {content}
          </Box>
        </Stack>
      </Row>
    </Block>
  )
}

export default MosaicBlock

function SmallQuote({ quote }: { quote: Quote }) {
  const { quote: text, name, title, photo } = quote
  return (
    <Row space={24}>
      <Stack space={24}>
        <Box
          font="medium"
          fontWeight={700}
          color="blue900"
          maxWidth={{ xs: '100%', md: 'largeBlurb' }}
          css={css`
            &::before {
              content: '“';
              width: 10px;
              margin-left: -10px;
              display: inline-block;
            }
            &::after {
              content: '”';
              width: 20px;
              /* Prevent flowing to second row*/
              margin-right: -20px;
              display: inline-block;
            }
            text-wrap: balance;
          `}
        >
          {text}
        </Box>
        <Row space={16} alignItems="center">
          <Box.img loading="lazy" width={64} height={64} src={photo} />
          <Stack position="relative">
            <Box font="small" fontWeight={700}>
              {name}
            </Box>
            <Box font="small">{title}</Box>
          </Stack>
        </Row>
      </Stack>
    </Row>
  )
}
